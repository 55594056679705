<template>
  <div>
    <b-row align-h="center">
      <b-col cols="6">
        <p class="mb-0 h4" v-text="$t(locale.title)"/>
      </b-col>
      <b-col cols="6" class="d-flex justify-content-end">
        <div
            class="d-flex smallGap text-secondaryColor align-items-center"
            role="button"
            @click="$router.push({ name: 'acountingInvoices' })"
        >
          <mdicon :width="25" :height="25" name="arrow-left-thick"/>
          <p class="mb-0" v-text="$t(locale.backTo)"/>
        </div>
      </b-col>

      <b-col cols="12" class="mt-2">
        <b-card>
          <div class="d-flex align-content-center justify-content-between">
            <span class="flex items-center gap-3">
              <b-card-title class="mb-0" v-text="$t($route.meta.pageTitle)"/>
              <strong> {{ singleInvoice.document_number }}</strong>
            </span>

            <div class="d-flex bigGap pr-2">
              <span class="flex gap-2 items-center">
                <p class="mb-0" v-text="$t(locale.ocrNumber)"/>
                <strong> {{ singleInvoice.ocr }}</strong>
              </span>

              <span class="flex gap-2 items-center">
                <p class="mb-0" v-text="$t(locale.voucherNumber)"/>

                <strong>
                  {{ singleInvoice.voucher_series }}-{{
                    singleInvoice.voucher_number
                  }}</strong
                >
              </span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" lg="12" v-if="loading === false">
        <SecondaryCard class="mt-2" :title="$t(locale.invoiceDetail)">
          <b-container fluid>
            <ValidationObserver ref="form">
              <b-row>
                <b-col :cols="12" :lg="4">
                  <span>
                    <b-form-group
                        :label="$t(locale.customers)"
                        label-for="input-1"
                    >
                      <v-select
                          label="name"
                          class="select-size-sm w-full"
                          :disabled="
                          singleInvoice.booked || singleInvoice.cancelled
                        "
                          v-model="singleInvoice.customer_name"
                          @option:selected="choosenCustomer($event)"
                          :filterable="false"
                          :options="customersResult"
                          @search="onSearch"
                      >
                        <template slot="no-options">
                          Sök...
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{
                              option.name + "(" + option.customer_number + ")"
                            }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>
                    </b-form-group>
                  </span>
                </b-col>
                <b-col cols="12" lg="2">
                  <b-form-group
                      :label="$t(locale.invoiceDate)"
                      label-for="input-1"
                  >
                    <date-picker
                        v-model="singleInvoice.invoice_date"
                        valueType="format"
                        class="w-100"
                        prefix-class="xmx"
                        :disabled="
                        singleInvoice.booked || singleInvoice.cancelled
                      "
                        :placeholder="$t(locale.invoiceDate)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2">
                  <b-form-group :label="$t(locale.dueDate)" label-for="input-1">
                    <date-picker
                        v-model="singleInvoice.due_date"
                        valueType="format"
                        class="w-100"
                        prefix-class="xmx"
                        :disabled="
                        singleInvoice.booked || singleInvoice.cancelled
                      "
                        :placeholder="$t(locale.dueDate)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2">
                  <b-form-group
                      :label="$t(locale.invoiceType)"
                      label-for="input-1"
                  >
                    <LittleTabs
                        :tabs="[
                        {
                          label: 'invoicesCard.invoice',
                          active: true,
                          value: 'INVOICE',
                        },
                        {
                          label: 'invoicesCard.cash',
                          active: false,
                          value: 'CASH',
                        },
                      ]"
                        :defaultValue="true"
                        v-model="singleInvoice.invoice_type"
                        buttonSize="sm"
                        containerStyle="justify-content-start"
                        btnClass="tabWrapperSmall2"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="2">
                  <b-form-group
                      :label="$t(locale.creditInvoice)"
                      label-for="input-1"
                  >
                    <LittleTabs
                        :tabs="[
                        {
                          label: 'CustomersCardEdit.yes',
                          active: true,
                          value: true,
                        },
                        {
                          label: 'CustomersCardEdit.no',
                          active: false,
                          value: false,
                        },
                      ]"
                        :defaultValue="true"
                        v-model="singleInvoice.credit"
                        buttonSize="sm"
                        containerStyle="justify-content-start"
                        btnClass="tabWrapperSmall2"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2">
                  <b-form-group
                      :label="$t(locale.paymentTerm)"
                      label-for="input-1"
                  >
                    <v-select
                        :disabled="singleInvoice.booked"
                        v-model="singleInvoice.terms_of_payment"
                        :placeholder="$t(locale.paymentTerm)"
                        @option:selected="
                        computeSelect($event, 'terms_of_payment')
                      "
                        :options="termsOfPayment"
                        class="resource-selector w-full select-size-sm d-inline-block"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2">
                  <b-form-group
                      :label="$t(locale.ourReference)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleInvoice.our_reference"
                        :placeholder="$t(locale.ourReference)"
                        :disabled="singleInvoice.booked"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2">
                  <b-form-group
                      :label="$t(locale.yourDate)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        :placeholder="$t(locale.yourDate)"
                        :disabled="singleInvoice.booked"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2">
                  <b-form-group
                      :label="$t(locale.yourOrderNumber)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleInvoice.your_order_number"
                        :placeholder="$t(locale.yourOrderNumber)"
                        :disabled="singleInvoice.booked"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2">
                  <b-form-group
                      :label="$t(locale.deliveryTerm)"
                      label-for="input-1"
                  >
                    <v-select
                        v-model="singleInvoice.terms_of_delivery"
                        :placeholder="$t(locale.deliveryTerm)"
                        :options="deliveryTerms"
                        :disabled="singleInvoice.booked"
                        @option:selected="
                        computeSelect($event, 'terms_of_delivery')
                      "
                        class="resource-selector w-full select-size-sm d-inline-block"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2">
                  <b-form-group
                      :label="$t(locale.deliveryMethod)"
                      label-for="input-1"
                  >
                    <v-select
                        v-model="singleInvoice.way_of_delivery"
                        :placeholder="$t(locale.deliveryMethod)"
                        :options="waysOfDelivery"
                        :disabled="singleInvoice.booked"
                        @option:selected="
                        computeSelect($event, 'way_of_delivery')
                      "
                        class="resource-selector w-full select-size-sm d-inline-block"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2">
                  <b-form-group
                      :label="$t(locale.currency)"
                      label-for="input-1"
                  >
                    <v-select
                        v-model="singleInvoice.currency"
                        :placeholder="$t(locale.currency)"
                        :options="currencies"
                        :disabled="singleInvoice.booked"
                        @option:selected="computeSelect($event, 'currency')"
                        class="resource-selector w-full select-size-sm d-inline-block"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2">
                  <b-form-group :label="$t(locale.yourRef)" label-for="input-1">
                    <b-form-input
                        size="sm"
                        v-model="singleInvoice.your_reference"
                        :placeholder="$t(locale.yourRef)"
                        :disabled="singleInvoice.booked"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2">
                  <b-form-group
                      :label="$t(locale.priceList)"
                      label-for="input-1"
                  >
                    <v-select
                        v-model="singleInvoice.price_list"
                        :placeholder="$t(locale.priceList)"
                        :options="priceLists"
                        :disabled="singleInvoice.booked"
                        @option:selected="computeSelect($event, 'price_list')"
                        class="resource-selector w-full select-size-sm d-inline-block"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2">
                  <b-form-group
                      :label="$t(locale.priceInvat)"
                      label-for="input-1"
                  >
                    <LittleTabs
                        :tabs="[
                        {
                          label: 'CustomersCardEdit.yes',
                          active: true,
                          value: true,
                        },
                        {
                          label: 'CustomersCardEdit.no',
                          active: false,
                          value: false,
                        },
                      ]"
                        :defaultValue="true"
                        v-model="singleInvoice.vat_included"
                        buttonSize="sm"
                        containerStyle="justify-content-start"
                        btnClass="tabWrapperSmall2"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </ValidationObserver>
          </b-container>
        </SecondaryCard>
      </b-col>

      <b-col cols="12" lg="6">
        <SecondaryCard class="mt-2" :title="$t(locale.customerDetails)">
          <b-container fluid>
            <b-row>
              <b-col cols="12" lg="6">
                <b-form-group :label="$t(locale.name)" label-for="input-1">
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.customer_name"
                      :placeholder="$t(locale.name)"
                      :disabled="singleInvoice.booked"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.organizationName)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.organisation_number"
                      :placeholder="$t(locale.organizationName)"
                      :disabled="singleInvoice.booked"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.invoiceAddress)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.address1"
                      :placeholder="$t(locale.invoiceAddress)"
                      :disabled="singleInvoice.booked"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group :label="$t(locale.phone)" label-for="input-1">
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.phone1"
                      :placeholder="$t(locale.phone)"
                      :disabled="singleInvoice.booked"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.invoiceAddress2)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.address2"
                      :placeholder="$t(locale.invoiceAddress2)"
                      :disabled="singleInvoice.booked"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group :label="$t(locale.email)" label-for="input-1">
                  <b-form-input
                      size="sm"
                      :disabled="singleInvoice.booked"
                      :value="
                      singleInvoice.email_information
                        ? singleInvoice.email_information.email_address_to
                        : ''
                    "
                      :placeholder="$t(locale.email)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-row>
                  <b-col cols="12" lg="6">
                    <b-form-group
                        :label="$t(locale.zipCode1)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          :value="singleInvoice.zip_code"
                          :placeholder="$t(locale.zipCode1)"
                          :disabled="singleInvoice.booked"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-form-group :label="$t(locale.city1)" label-for="input-1">
                      <b-form-input
                          size="sm"
                          :value="singleInvoice.city"
                          :placeholder="$t(locale.city1)"
                          :disabled="singleInvoice.booked"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" lg="6">
                <b-row>
                  <b-col cols="12" lg="6">
                    <b-form-group :label="$t(locale.zip2)" label-for="input-1">
                      <b-form-input
                          size="sm"
                          :placeholder="$t(locale.zip2)"
                          :disabled="singleInvoice.booked"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-form-group :label="$t(locale.city2)" label-for="input-1">
                      <b-form-input
                          size="sm"
                          :placeholder="$t(locale.city2)"
                          :disabled="singleInvoice.booked"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
        </SecondaryCard>
      </b-col>

      <b-col cols="12" lg="6">
        <SecondaryCard class="mt-2" :title="$t(locale.deliveryDetails)">
          <b-container fluid>
            <b-row>
              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.deliveryCustomerName)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.delivery_name"
                      :placeholder="$t(locale.deliveryCustomerName)"
                      :disabled="singleInvoice.booked"
                  />
                </b-form-group>
              </b-col>

              <!-- <b-col cols="12" lg="6">
                                <b-form-group
                                        :label="$t(locale.delivOrgName)"
                                        label-for="input-1"
                                    >
                                    <b-form-input
                                        size="sm"
                                        :value="singleInvoice.customer_name"
                                        :placeholder="$t(locale.delivOrgName)"
                                    />
                                </b-form-group>
                            </b-col> -->

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.deliAddress1)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.delivery_address1"
                      :placeholder="$t(locale.deliAddress1)"
                      :disabled="singleInvoice.booked"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group :label="$t(locale.deliPhone)" label-for="input-1">
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.phone2"
                      :placeholder="$t(locale.deliPhone)"
                      :disabled="singleInvoice.booked"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.deliAddress2)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.delivery_address2"
                      :placeholder="$t(locale.deliAddress2)"
                      :disabled="singleInvoice.booked"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.delivEmail)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :disabled="singleInvoice.booked"
                      :value="
                      singleInvoice.email_information
                        ? singleInvoice.email_information.email_address_from
                        : ''
                    "
                      :placeholder="$t(locale.delivEmail)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-row>
                  <b-col cols="12" lg="6">
                    <b-form-group
                        :label="$t(locale.delivZip)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          :value="singleInvoice.delivery_zip_code"
                          :placeholder="$t(locale.delivZip)"
                          :disabled="singleInvoice.booked"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-form-group
                        :label="$t(locale.delivCity)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          :value="singleInvoice.delivery_city"
                          :placeholder="$t(locale.delivCity)"
                          :disabled="singleInvoice.booked"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" lg="6">
                <b-row>
                  <b-col cols="12" lg="6">
                    <b-form-group
                        :label="$t(locale.delivZip2)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          :placeholder="$t(locale.delivZip2)"
                          :disabled="singleInvoice.booked"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-form-group
                        :label="$t(locale.delivCity2)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          :placeholder="$t(locale.delivCity2)"
                          :disabled="singleInvoice.booked"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
        </SecondaryCard>
      </b-col>

      <!-- InvoiceRow -->
      <invoiceRowComponentVue
          :invoice_rows="singleInvoice.invoice_rows"
          :invoice_country="currentCustomer.country_code"
          @removeRow="removeRow"
          :key="singleInvoice.invoice_rows.length"
          @calculateRow="reCalculateValues"
          :booked="singleInvoice.booked"
          :cancelled="singleInvoice.cancelled"
          :vat_included="singleInvoice.vat_included"
          :fully_paid="singleInvoice.fully_paid"
          :submitForm="submitForm"
          :locale="locale"
      />
      <!-- @updateContract="updatesingleInvoice" -->

      <b-col cols="12" lg="8">
        <SecondaryCard class="mt-2" :title="$t(locale.otherDetails)">
          <b-container fluid>
            <b-row>
              <!-- <b-col cols="12" lg="6">
                                <b-form-group
                                        :label="$t(locale.shippingCharge)"
                                        label-for="input-1"
                                    >
                                    <b-form-input
                                        size="sm"
                                        :placeholder="$t(locale.shippingCharge)"
                                    />
                                </b-form-group>
                            </b-col> -->

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.invoiceCharge)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      v-model="singleInvoice.freight"
                      :placeholder="$t(locale.invoiceCharge)"
                      :disabled="singleInvoice.booked"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.invoiceDiscount)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :placeholder="$t(locale.invoiceDiscount)"
                      :disabled="singleInvoice.booked"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.printTemplate)"
                    label-for="input-1"
                >
                  <!-- {{printTemplates}} -->
                  <v-select
                      v-model="singleInvoice.print_template"
                      :disabled="singleInvoice.booked"
                      :placeholder="$t(locale.printoutTemplate)"
                      :options="printTemplates"
                      class="resource-selector w-full select-size-sm d-inline-block"
                  >
                    <!-- <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template> -->
                  </v-select>
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group :label="$t(locale.language)" label-for="input-1">
                  <v-select
                      :placeholder="$t(locale.language)"
                      :disabled="singleInvoice.booked"
                      :options="[]"
                      @option:selected="computeSelect($event, 'language')"
                      class="resource-selector w-full select-size-sm d-inline-block"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group :label="'Comment'">
                  <b-form-textarea
                      size="lg"
                      :disabled="singleInvoice.cancelled || singleInvoice.booked"
                      v-model="singleInvoice.comments"
                      :placeholder="'Comment'"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </SecondaryCard>
      </b-col>

      <b-col cols="12" lg="4">
        <b-card class="mt-2">
          <b-card-text class="d-flex flex-column smallGap">
            <div
                v-for="(faktur, index) in fakture"
                :key="index"
                class="d-flex align-items-center"
            >
              <p class="mb-0" v-text="$t(faktur.label)"/>
              <div class="border-between"/>
              <p class="mb-0" v-text="faktur.value"/>
            </div>

            <div class="sds devider my-2"/>

            <div class="d-flex justify-content-between align-items-center">
              <p class="mb-0 font-weight-bold" v-text="$t(locale.total)"/>

              <p class="mb-0">{{ singleInvoice.total_to_pay }}</p>
            </div>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col cols="12" class="mt-2">
        <b-row align-v="center">
          <b-col cols="12" lg="6">
            <div
                class="d-flex bigGap  align-items-center"
            >
              <p class="mb-0 h6" v-text="$t(locale.sendInvoice)"/>
              <div class="">
                <b-form-select
                    id="perPageSelect"
                    size="sm"
                    inline
                    v-model="actionLable"
                >
                  <b-form-select-option
                      v-for="(option, index) in actionButton"
                      :key="index"
                      :value="option.value"
                      v-text="$t(option.label)"
                  />
                </b-form-select>
              </div>

              <b-button
                  size="sm"
                  variant="primary"
                  @click="applyMultiAction(actionLable)"
                  v-text="
                            loadingAction
                              ? $t('Message.loading')
                              : $t(locale.apply)
                          "
              />
            </div>
          </b-col>
          <b-col cols="12" lg="6" class="d-flex smallGap justify-content-end">
            <b-button
                v-for="(btn, index) in buttonList"
                :key="index"
                :variant="btn.variant"
                :disabled="btn.processing || singleInvoice.cancelled"
                @click="btn.click(btn)"
            >
              <!-- @click="btn.submit ? updateContract() : $router.go(-1)" -->
              <span>
                <span v-if="btn.submit">
                  <span v-if="btn.processing">{{ $t("Message.loading") }}</span>
                  <span v-else>{{ $t(btn.label) }}</span>
                </span>
                <span v-else>{{ $t(btn.label) }}</span>
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <Modal
        id="modal-registerPayment"
        :hideFooter="true"
        :title="$t(locale.registerPayment)"
    >
      <ValidationObserver ref="registerForm">
        <ValidationProvider
            #default="{ errors }"
            v-for="(form, index) in registerPaymentForm"
            :rules="form.rules"
            :name="$t(form.label)"
            :key="index"
        >
          <div class="d-flex flex-column mt-1">
            <label v-if="index === 1" class="h6 font-weight-bold">
              {{ $t(form.label) }} (<strong>{{ singleInvoice.currency }}</strong>)
              <span class="text-danger">*</span>
            </label>
            <label class="h6 font-weight-bold" v-else>
              {{ $t(form.label) }}
              <span class="text-danger">*</span>
            </label>

            <template v-if="index === 0">
              <date-picker
                  v-model="form.value"
                  valueType="format"
                  class="w-100"
                  prefix-class="xmx"
                  :placeholder="$t(form.label)"
              />
              <small class="text-danger" v-text="errors[0]"/>
            </template>

            <template v-if="index === 1">
              <b-form-input
                  size="sm"
                  type="number"
                  min="0"
                  step="0.1"
                  v-model="form.value"
                  :placeholder="$t(form.label)"
              />
            </template>

            <template v-if="index === 2">
              <v-select
                  class="w-full"
                  v-model="form.value"
                  label="number"
                  @option:selected="changeAccounts($event, form)"
                  :filterable="false"
                  :options="accountResults"
                  @search="searchAccounts"
              >
                <template slot="no-options"> Sök...</template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.number + " " + option.description }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.number }}
                  </div>
                </template>
              </v-select>
            </template>
          </div>
        </ValidationProvider>

        <div class="flex justify-end gap-3 mt-2">
          <b-button
              variant="outline-danger"
              size="md"
              @click="$bvModal.hide('modal-registerPayment')"
          >
            <span v-text=" $t('Message.cancel')"/>
          </b-button>
          <b-button
              variant="primary"
              size="md"
              :disabled="sendingModalAction"
              @click="registerPaymentInvoice"
          >
            <span
                v-text="sendingModalAction ? $t('Message.loading') : 'Send'"
            />
          </b-button>
        </div>
      </ValidationObserver>
    </Modal>

    <Modal id="modal-void-invoice" :hideFooter="true" :title="'Cancel Invoice'">
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          Är du säker på att du vill radera faktura
          {{ singleInvoice.document_number }}?
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-void-invoice')"
        >
          <span v-text="$t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="voidInvoice"
        >
          <span
              v-text="sendingModalAction ? $t('Message.loading') :  $t('Message.proceed')"
          />
        </b-button>
      </div>
    </Modal>
    <Modal

        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import debounce from "lodash.debounce";
import {registersMixin} from "@/mixins/registersMixin";
import {dateMixins} from "@/mixins/dateMixins";
import moment from "moment";
import invoiceRowComponentVue from "./invoiceRowComponent.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import bus from "@/bus";
import {mapGetters} from "vuex"
import accessDenied from "@core/components/permission/accessDenied";

export default {
  name: "createInvoices",
  mixins: [registersMixin, dateMixins],
  components: {
    vSelect,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    invoiceRowComponentVue, accessDenied
  },
  data() {
    return {
      config: useAppConfig(),
      loading: true,
      singleInvoice: {invoice_rows: []},
      customersResult: [],
      selectedCustomer: {},
      waysOfDelivery: [],
      deliveryTerms: [],
      termsOfPayment: [],
      printTemplates: [],
      priceLists: [],
      currencies: [],
      projects: [],
      costCenter: [],
      updating: false,
      actionLable: '',
      loadingAction: false,
      actionButton: [

        {
          label: "invoicesCard.email",
          variant: "dark",
          value: "email",
        },
        {
          label: "invoicesCard.einvoice",
          variant: "dark",
          value: "einvoice",
        },
        {
          label: "invoicesCard.receipt",
          variant: "dark",
          value: "receipt",
        },
        {
          label: "invoicesCard.reminder",
          variant: "dark",
          value: "reminder",
        },
        //{
        //  label: "invoicesCard.preview",
        //  variant: "dark",
        //  value: "preview",
        //},
        //{
        //  label: "invoicesCard.print",
        //  variant: "dark",
        //  value: "print",
        //},
        //{
        //  label: "invoicesCard.printreminder",
        //  variant: "dark",
        //  value: "printreminder",
        //},
        //{
        //  label: "invoicesCard.externalprint",
        //  variant: "dark",
        //  value: "externalprint",
        //},
        // {
        //     label: 'invoicesCard.schedule',
        //     variant: 'dark',
        //     value: 'schedule',
        // },
      ],
      currentCustomer: {},
      previousVatIncluded: false,
      submitForm: false,

      fakture: [
        {
          label: "invoicesEditCard.net",
          value: 179.1,
          fieldValue: "net",
        },
        {
          label: "invoicesEditCard.gross",
          value: 179.1,
          fieldValue: "gross",
        },
        {
          label: "invoicesCard.table.vat",
          value: 179.1,
          fieldValue: "total_vat",
        },
        {
          label: "invoicesEditCard.totalExVat",
          value: 179.1,
          fieldValue: "total",
        },
      ],

      buttonList: [
        {
          label: "UsersCard.cancel",
          value: "cancel",
          variant: "outline-secondary",
          click: this.cancelData,
          processing: false,
          submit: false,
        },
        {
          label: "invoicesEditCard.saveInvoice",
          value: "save",
          variant: "primary",
          click: this.saveInvoice,
          processing: false,
          submit: true,
        },
        {
          label: "invoicesEditCard.bookkeepInvoice",
          value: "bookkeep",
          variant: "secondaryColor",
          click: this.runbookkeepInvoice,
          processing: false,
          submit: true,
        },
        {
          label: "invoicesEditCard.creditInvoices",
          value: "bookkeep",
          variant: "warning",
          click: this.creditInvoice,
          processing: false,
          submit: true,
        },
        {
          label: "invoicesEditCard.cancelInvoices",
          value: "bookkeep",
          variant: "danger",
          click: this.cancelInvoice,
          processing: false,
          submit: false,
        },
        {
          label: "invoicesEditCard.registerPayment",
          value: "bookkeep",
          variant: "success",
          click: this.registerPayment,
          processing: false,
          submit: false,
        },
      ],

      registerPaymentForm: [
        {
          label: "invoicesCard.paymentDate",
          rules: "required",
          type: "date",
          value: "",
          fieldValue: "payment_date",
        },
        {
          label: "invoicesCard.paymentAmount",
          rules: "required",
          type: "input",
          value: "",
          fieldValue: "amount",
        },
        {
          label: "invoicesCard.account",
          rules: "required",
          type: "select",
          value: "",
          fieldValue: "mode_of_payment_account",
        },
      ],
      accountResults: [],
      sendingModalAction: false,

      locale: {
        ocrNumber: "invoicesEditCard.ocrNumber",
        voucherNumber: "invoicesEditCard.voucherNumbers",
        apply: "golfRelatedCard.apply",
        action: "golfRelatedCard.action",
        invoiceDetail: "invoicesEditCard.invoiceDetails",
        customerDetails: "CustomersCardEdit.customerDetail",
        deliveryDetails: "invoicesEditCard.deliveryDetails",
        invoiceRow: "invoicesEditCard.invoiceRows",
        otherDetails: "invoicesEditCard.othersDetails",
        customers: "StatisticsCard.tabs.customers",
        invoiceDate: "invoicesEditCard.invoiceDate",
        dueDate: "dashboardCard.table.dueDateHead",
        invoiceType: "invoicesCard.invoiceType",
        creditInvoice: "invoicesEditCard.creditInvoice",
        paymentTerm: "InvoiceCardCustomerEdit.paymentTerms",
        ourReference: "InvoiceCardCustomerEdit.ourReference",
        yourDate: "invoicesEditCard.yourDate",
        yourOrderNumber: "invoicesEditCard.yourOrderNumber",
        deliveryTerm: "InvoiceCardCustomerEdit.deliveryTerms",
        deliveryMethod: "InvoiceCardCustomerEdit.deliveryMethod",
        currency: "InvoiceCardCustomerEdit.currency",
        yourRef: "InvoiceCardCustomerEdit.yourReference",
        priceList: "invoicesEditCard.priceList",
        priceInvat: "invoicesEditCard.priceIncVat",
        name: "Message.tableHeader.name",
        organizationName: "invoicesEditCard.organisationNumber",
        invoiceAddress: "invoicesEditCard.invoiceAddress1",
        phone: "CustomersCard.table.phone",
        invoiceAddress2: "invoicesEditCard.invoiceAddress1",
        email: "Message.tableHeader.email",
        zipCode1: "BillingPageCard.leftSide.Form.zipCode",
        city1: "BillingPageCard.leftSide.Form.city",
        zip2: "BillingPageCard.leftSide.Form.zipCode",
        city2: "BillingPageCard.leftSide.Form.city",
        deliveryCustomerName: "Message.tableHeader.name",
        delivOrgName: "invoicesEditCard.organisationNumber",
        deliAddress1: "invoicesEditCard.invoiceAddress1",
        deliPhone: "CustomersCard.table.phone",
        deliAddress2: "invoicesEditCard.invoiceAddress1",
        delivEmail: "Message.tableHeader.email",
        delivZip: "BillingPageCard.leftSide.Form.zipCode",
        delivCity: "BillingPageCard.leftSide.Form.city",
        delivZip2: "BillingPageCard.leftSide.Form.zipCode",
        delivCity2: "BillingPageCard.leftSide.Form.city",
        shippingCharge: "InvoiceCardCustomerEdit.shippingCharge",
        invoiceCharge: "InvoiceCardCustomerEdit.invoiceCharge",
        invoiceDiscount: "InvoiceCardCustomerEdit.invoiceDiscount",
        printTemplate: "invoicesEditCard.printoutTemplate",
        language: "invoicesEditCard.language",
        InvoiceText: "InvoiceCardCustomerEdit.invoiceText",

        send: "Contact.contactInformation.form.button",
        title: "invoicesCard.editInvoices",
        sendInvoice: "invoicesEditCard.sendInvoice",
        backTo: "golfRelatedCard.detail.backOverview",
        total: "invoicesCard.table.total",
      },
    };
  },
  watch: {
    "singleInvoice.vat_included": function (newVal, oldVal) {
      this.reCalculateValues(null);
    },
    "singleInvoice.credit": function (newVal, oldVal) {
      bus.$emit("perform-delivery-qty");
    },
  },
  computed: {
    message() {
      return this.GET_LOCALE === "sv" ? "swe_message" : "eng_message";
    },
    features() {
      return this.GET_LOCALE === "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    const {id} = this.$route.params;
    if (id) {
      this.getSingleInvoice(id);
    }
    this.getAllRegisters();
    this.fetchCustomer();
  },
  methods: {
    openAccessDeniedModal() {
      this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
      this.$bvModal.show("modal-access-denied");

    },
    applyMultiAction(value) {
      if (value) {
        // this.singleInvoice
        let URL = `${this.singleInvoice.uuid}/${value}`
        const mode = {URL: `/${this.singleInvoice.uuid}/${value}`, method: "put"};

        this.sendingModalAction = true;

        this.$useJwt
            .getInvoices(mode)
            .then((res) => {
              this.sendingModalAction = false;
              this.getSingleInvoice(this.singleInvoice.uuid);
              this.popupMsg(
                  this.$t("Message.Success"),
                  res.data[this.locale_message],
                  "CheckIcon",
                  "success"
              );
              this.$bvModal.hide("modal-registerPayment");
            })
            .catch((err) => {
              this.sendingModalAction = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.locale_message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });

      }
    },
    async getAllRegisters() {
      const waysOfDelivery = await this.$useJwt.getAllRegisters(
          "wayofdelivery"
      );
      const deliveryTerms = await this.$useJwt.getAllRegisters(
          "termsofdelivery"
      );
      const termsOfPayment = await this.$useJwt.getAllRegisters(
          "termsofpayment"
      );
      const printTemplates = await this.$useJwt.getAllRegisters(
          "printtemplates?type=invoice"
      );
      const priceLists = await this.$useJwt.getAllRegisters("pricelists");
      const currencies = await this.$useJwt.getAllRegisters("currencies");
      const costcenter = await this.$useJwt.getAllRegisters("costcenters");
      const projects = await this.$useJwt.getAllRegisters("projects");

      this.waysOfDelivery = waysOfDelivery.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
      this.deliveryTerms = deliveryTerms.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
      this.termsOfPayment = termsOfPayment.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
      this.printTemplates = printTemplates.data.results.map((el) => {
        return {label: el.name, value: el.template, id: el.uuid};
      });
      this.priceLists = priceLists.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
      this.currencies = currencies.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
      this.costCenter = costcenter.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
      this.projects = projects.data.results.map((el) => {
        return {label: el.description, value: el.code};
      });
    },
    getSingleInvoice(uuid) {
      this.loading = true;
      this.$useJwt
          .getInvoices({URL: `/${uuid}`, method: "get"})
          .then((res) => {
            this.singleInvoice = res.data.data;
            this.previousVatIncluded = this.singleInvoice.vat_included;
            this.getCurrentCustomer(this.singleInvoice.customer_uuid);
            this.reCalculateValues(null)
            this.loading = false;

            this.fakture.map((el) => {
              if (this.singleInvoice[el.fieldValue]) {
                el.value = this.singleInvoice[el.fieldValue];
              }
            });
          })
          .catch((err) => {
            this.loading = false
            if (err.response.status === 403) {
              window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
            }
          });
    },
    getCurrentCustomer(id) {
      this.$useJwt.getCustomersByid(`${id}`).then((res) => {
        this.currentCustomer = res.data.data;
      });
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce(function (loading, search, vm) {
      this.$useJwt
          .searchCustomers(`?search=${escape(search)}`)
          .then((res) => {
            this.customersResult = res.data.data;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    fetchCustomer: function () {
      this.$useJwt.searchCustomers(``)
          .then(res => {
            this.customersResult = res.data.data
          })
          .catch(err => {
          })
    },
    choosenCustomer(customer) {
      this.currentCustomer = customer;
      if (!customer.active) {
        this.popupMsg(
            this.$t("Message.Failed"),
            "This customer is not active. Please select another customer.",
            "AlertTriangleIcon",
            "danger"
        );
        form.value = "";
        return;
      }
      this.singleInvoice.customer_number = customer.customer_number;
      this.singleInvoice.customer_name = customer.name;
      this.singleInvoice.address1 = customer.address1;
      this.singleInvoice.address2 = customer.address2;
      this.singleInvoice.zip_code = customer.zip_code;
      this.singleInvoice.city = customer.city;
      this.singleInvoice.organisation_number = customer.organisation_number;
      this.singleInvoice.phone1 = customer.phone1;
      this.singleInvoice.city = customer.city;
      this.singleInvoice.country = customer.country;
      this.singleInvoice.delivery_name = customer.delivery_name;
      this.singleInvoice.delivery_address1 = customer.delivery_address1;
      this.singleInvoice.delivery_address2 = customer.delivery_address2;
      this.singleInvoice.delivery_zip_code = customer.delivery_zip_code;
      this.singleInvoice.delivery_city = customer.delivery_city;
      this.singleInvoice.delivery_date = customer.delivery_date;
      this.singleInvoice.phone2 = customer.phone2;
      this.singleInvoice.email_address_to = customer.email_address_to;
      this.singleInvoice.delivery_city = customer.delivery_city;
      this.singleInvoice.delivery_country = customer.delivery_country;
    },
    computeSelect(item, el) {
      this.singleInvoice[el] = item.value;
    },
    removeRow(item) {
      // if (this.CURRENT_USER.access === "Read") {
      //     return this.$toast.info(this.$t("limited_read_access"));
      // }
      if (this.singleInvoice.booked === true) {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Invoice already bookkept , invoice row can not be removed",
            "AlertTriangleIcon",
            "danger"
        );
        return;
      }
      // const filter = this.singleInvoice.invoice_rows.filter(el => el.row_id !== item.row_id)
      // this.singleInvoice.invoice_rows = filter
      this.singleInvoice.invoice_rows.splice(item, 1);
      this.reCalculateValues(null);
    },
    switchLittleTabs(index, tabs, type) {
      tabs.find((item) => item.active == true).active = false;
      this.$nextTick(() => {
        this.singleInvoice[type] = tabs[index].value;

        tabs[index].active = true;
      });
    },
    reCalculateValues(item = null) {
      let total = 0;
      let total_excluding_vat = 0;
      let total_vat = 0;

      this.singleInvoice.invoice_rows.map((element) => {
        let vat_amount = 0;

        total_excluding_vat += parseFloat(element.total_excluding_vat);

        vat_amount =
            element.total_excluding_vat * (1 + element.vat / 100) -
            element.total_excluding_vat;
        total += parseFloat(
            element.total_excluding_vat * (1 + element.vat / 100)
        );
        total_vat += vat_amount;

        if (this.singleInvoice.vat_included) {
          element.total = (
              parseInt(element.delivered_quantity) *
              parseFloat(element.price_excluding_vat).toFixed(2) *
              parseFloat(1 + parseFloat(element.vat) / 100).toFixed(2) *
              (
                  1 - parseFloat(this.removePercentage(element.discount) / 100)
              ).toFixed(2)
          ).toFixed(2);
          element.price = (
              parseFloat(element.price_excluding_vat).toFixed(2) *
              parseFloat(1 + parseFloat(element.vat) / 100).toFixed(2)
          ).toFixed(2);
        } else {
          element.total = (
              parseInt(element.delivered_quantity) *
              parseFloat(element.price_excluding_vat).toFixed(2) *
              (
                  1 - parseFloat(this.removePercentage(element.discount) / 100)
              ).toFixed(2)
          ).toFixed(2);

          element.total_excluding_vat = parseFloat(
              element.total_excluding_vat
          ).toFixed(2);

          element.price_excluding_vat = parseFloat(
              element.price_excluding_vat
          ).toFixed(2);

          element.price = parseFloat(element.price_excluding_vat).toFixed(2);
        }
      });

      if (item !== null) {
        if (item.delivered_quantity && item.price_per_unit) {
          if (this.singleInvoice.vat_included) {
            item.total = (
                parseInt(item.delivered_quantity) *
                parseFloat(item.price_per_unit) *
                (1 -
                    parseFloat(this.removePercentage(item.discount) / 100).toFixed(
                        2
                    ))
            ).toFixed(2);
            item.total_excluding_vat = (
                item.total /
                (1 + parseFloat(item.vat) / 100)
            ).toFixed(2);
          } else {
            item.total_excluding_vat = (
                parseInt(item.delivered_quantity) *
                parseFloat(
                    item.price_per_unit *
                    parseFloat(
                        1 -
                        parseFloat(
                            this.removePercentage(item.discount) / 100
                        ).toFixed(2)
                    ).toFixed(2)
                )
            ).toFixed(2);
            item.total = (
                item.total_excluding_vat *
                (1 + parseFloat(item.vat) / 100)
            ).toFixed(2);
          }

          let vat = 0;
          // total += parseFloat(item.total);
          total_excluding_vat += parseFloat(item.total_excluding_vat).toFixed(
              2
          );

          vat = (item.total - item.total_excluding_vat).toFixed(2);
          total_vat += vat;
        }
      }

      this.singleInvoice.gross = parseFloat(total).toFixed(2);
      this.singleInvoice.total_vat = parseFloat(total_vat).toFixed(2);
      this.singleInvoice.total = parseFloat(total_excluding_vat).toFixed(2);
      this.singleInvoice.total_to_pay = parseFloat(total).toFixed(2);
      this.fakture[0].value = this.singleInvoice.total;
      this.fakture[1].value = this.singleInvoice.gross;
      this.fakture[2].value = this.singleInvoice.total_vat;
      this.fakture[3].value = this.singleInvoice.total;
    },
    updatesingleInvoice(bookkeep = false, buttonItem = null) {
      this.submitForm = false;
      // if (this.CURRENT_USER.access === "Read") {
      //     return this.$toast.info(this.$t("limited_read_access"));
      // }
      if (this.singleInvoice.cancelled === true) {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Cancelled invoice can not be modified",
            "AlertTriangleIcon",
            "danger"
        );
        return false
        // return this.$services.helpers.notification(
        // "Cancelled invoice can not be modified",
        // "error",
        // this
        // );
      }
      buttonItem.processing = buttonItem ? true : false;
      this.processing = true;
      let data_to_save = {};
      if (this.singleInvoice.booked === false) {
        let exclude = [
          "email_information",
          "edi_information",
          "invoice_rows",
          "labels",
          "due_date",
          "invoice_date",
          "not_completed",
          "delivery_date",
          "failed_to_pause",
          "file",
          "fully_paid",
        ];

        let singleInvoice = this.singleInvoice;
        let email_information = {};
        let edi_information = {};
        let invoice_row = this.singleInvoice.invoice_rows;
        let new_invoice_rows = [];
        for (const [key, value] of Object.entries(singleInvoice)) {
          if (!exclude.includes(key)) {
            data_to_save[key] = value;
            // if (value !== "" && value !== null && value !== undefined) {
            //
            // }
          }
          if (["due_date", "invoice_date", "delivery_date"].includes(key)) {
            if (value !== "" && value !== null && value !== undefined) {
              data_to_save[key] = moment(value).format("YYYY-MM-DD");
            }
          }
        }
        for (const [key, value] of Object.entries(
            singleInvoice.email_information
        )) {
          if (value !== null && value !== "" && value !== undefined) {
            email_information[key] = value;
          }
        }
        for (const [key, value] of Object.entries(
            singleInvoice.edi_information
        )) {
          if (value !== null && value !== "" && value !== undefined) {
            edi_information[key] = value;
          }
        }
        if (Object.keys(email_information).length > 0) {
          data_to_save["email_information"] = email_information;
        }
        if (Object.keys(edi_information).length > 0) {
          data_to_save["edi_information"] = edi_information;
        }
        invoice_row.map((item) => {
          for (const [key, value] of Object.entries(item)) {
            if (value === "" || value === null) {
              delete item[key];
            }
          }
          new_invoice_rows.push(JSON.parse(JSON.stringify(item)));
        });
        if (this.previousVatIncluded !== this.singleInvoice.vat_included) {
          // for each item in invoice_rows, remove vat from price and total fields
          if (this.singleInvoice.vat_included === true) {
            new_invoice_rows.forEach((item) => {
              if (item.newRow || item.newRow === true) {
                item.price = parseFloat(item.price).toFixed(2);
                item.total = parseFloat(item.total).toFixed(2);
              } else {
                item.price = parseFloat(
                    item.price / (1 + item.vat / 100)
                ).toFixed(2);
                item.total = parseFloat(
                    item.total / (1 + item.vat / 100)
                ).toFixed(2);
              }
            });
          } else {
            new_invoice_rows.forEach((item) => {
              // if item.newRow is true, then it's a new row, so don't change price or total
              if (item.newRow || item.newRow === true) {
                item.price = parseFloat(item.price).toFixed(2);
                item.total = parseFloat(item.total).toFixed(2);
              } else {
                item.price = parseFloat(
                    item.price * (1 + item.vat / 100)
                ).toFixed(2);
                item.total = parseFloat(
                    item.total * (1 + item.vat / 100)
                ).toFixed(2);
              }
            });
          }
        }
        data_to_save.invoice_rows = new_invoice_rows;
      } else {
        data_to_save["comments"] = this.singleInvoice.comments;
        // if (
        //   data_to_save.comments === "" ||
        //   data_to_save.comments === null ||
        //   data_to_save.comments === undefined
        // ) {
        //   buttonItem.processing = buttonItem ? false : false;
        //   this.processing = false;
        //   this.popupMsg(
        //     this.$t("Message.Failed"),
        //     "Kindly supply valid comment",
        //     "AlertTriangleIcon",
        //     "danger"
        //   );
        //   return;
        // }
      }


      const payload = data_to_save;
      const mode = {URL: `/${this.singleInvoice.uuid}`, method: "put"};

      this.$useJwt
          .getInvoices(mode, payload)
          .then((res) => {
            if (bookkeep) {
              this.modifiedInvoice("bookkeep", buttonItem);
            } else {
              this.popupMsg(
                  this.$t("Message.Success"),
                  res.data[this.locale_message],
                  "CheckIcon",
                  "success"
              );
              buttonItem.processing = buttonItem ? false : false;
              this.processing = false;
              this.submitForm = false;
              // setTimeout(() => {
              //     window.location.reload(true);
              // }, 500);
            }
          })
          .catch((err) => {
            buttonItem.processing = buttonItem ? false : false;
            this.sending = false;
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data.message,
                "AlertTriangleIcon",
                "danger"
            );
            if (err.response.status === 403) {
              window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
            }
          });
    },
    searchAccounts(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteAccounts(loading, search, this);
      }
    },
    searchRemoteAccounts: debounce(function (loading, search, vm) {
      this.$useJwt
          .getAllRegisters(`accounts?search=${escape(search)}`)
          .then((res) => {
            this.accountResults = res.data.results;
            loading(false);
          })
          .catch((err) => {
          });
    }, 350),
    changeAccounts(account) {
      this.registerPaymentForm[2].value = account.number;
    },
    registerPaymentInvoice() {
      this.$refs.registerForm.validate().then((res) => {
        if (res) {
          const payload = {};
          this.registerPaymentForm.map((el) => {
            payload[el.fieldValue] = el.value;
          });

          if (parseFloat(payload["amount"])) {
            payload["invoice_number"] = this.singleInvoice.document_number;
            const mode = {URL: "/", method: "post"};

            // const validateDate = this.validatePresentDate(
            //     payload["date_to_send"]
            // );

            // if (!validateDate) {
            this.sendingModalAction = true;

            this.$useJwt
                .getInvoicePayment(mode, payload)
                .then((res) => {
                  this.registerPaymentForm.map((el) => {
                    el.value = "";
                  });
                  this.sendingModalAction = false;
                  this.popupMsg(
                      this.$t("Message.Success"),
                      res.data[this.locale_message],
                      "CheckIcon",
                      "success"
                  );
                  this.$bvModal.hide("modal-registerPayment");
                })
                .catch((err) => {
                  this.sendingModalAction = false;
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data.message,
                      "AlertTriangleIcon",
                      "danger"
                  );
                });
            // } else {
            //   this.popupMsg(
            //       this.$t("Message.Failed"),
            //       "Date is in the past",
            //       "AlertTriangleIcon",
            //       "danger"
            //   );
            // }
          } else {
            this.popupMsg(
                this.$t("Message.Failed"),
                "Please input a valid amount",
                "AlertTriangleIcon",
                "danger"
            );
          }
        }
      });
    },
    voidInvoice() {
      const payload = this.singleInvoice;
      const mode = {URL: `/${this.singleInvoice.uuid}/cancel`, method: "put"};

      this.sendingModalAction = true;

      this.$useJwt
          .getInvoices(mode, payload)
          .then((res) => {
            this.sendingModalAction = false;
            this.getSingleInvoice(this.singleInvoice.uuid);
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.locale_message],
                "CheckIcon",
                "success"
            );
            this.$bvModal.hide("modal-registerPayment");
          })
          .catch((err) => {
            this.sendingModalAction = false;
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.locale_message],
                "AlertTriangleIcon",
                "danger"
            );
          });
    },
    cancelData() {
      this.$router.go(-1);
    },
    saveInvoice(item) {
      this.updatesingleInvoice(false, item);
    },
    runbookkeepInvoice(item) {
      if (!this.singleInvoice.cancelled) {
        this.updatesingleInvoice(true, item);
      }
    },
    creditInvoice(item) {
      if (!this.singleInvoice.cancelled) {
        this.modifiedInvoice("credit", item);
      }
      return;
    },
    modifiedInvoice(core, item) {
      // if (this.CURRENT_USER.access === "Read") {
      //     return this.$toast.info(this.$t("limited_read_access"));
      // }
      if (this.singleInvoice.cancelled == true) {
        item.processing = false;
        this.popupMsg(
            this.$t("Message.Failed"),
            "Cancelled invoice can not be modified",
            "AlertTriangleIcon",
            "danger"
        );
      }

      const payload = this.singleInvoice;
      const mode = {
        URL: `/${this.singleInvoice.uuid}/${core}`,
        method: "put",
      };

      item.processing = true;
      this.$useJwt
          .getInvoices(mode, payload)
          .then((res) => {
            item.processing = false;
            this.getSingleInvoice(this.singleInvoice.uuid);
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.locale_message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            item.processing = false;
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.locale_message],
                "AlertTriangleIcon",
                "danger"
            );
          });
    },
    cancelInvoice() {
      if (!this.singleInvoice.booked) {
        // open Cancel confirmation
        this.$bvModal.show("modal-void-invoice");
      } else {
        this.popupMsg(
            this.$t("Message.Failed"),
            "Invoice already booked",
            "AlertTriangleIcon",
            "danger"
        );
      }
    },
    registerPayment() {
      this.$bvModal.show("modal-registerPayment");
    },
  },
};
</script>

<style scoped lang="scss">
.resource-selector w-full select-size-sm {
  width: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/datepicker.scss";
</style>

<style scoped>
.border-between {
  display: block;
  border-top: 1px dashed #cbd4e1;
  flex-grow: 1;
  margin: 0 10px;

  margin-top: 2px;
}

.devider {
  border: 1px solid #64748b;
}
</style>
